<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list">
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-select
              v-model="campiricerca.type"
              :options="options"
              class="border-0"
              style="max-width: 220px;"
            />
            <div>
              <validation-observer ref="simpleRules">
                <validation-provider
                  #default="{ errors }"
                  name="Info ricerca"
                  rules="required"
                >
                  <b-form-input
                    v-model="campiricerca.value"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Ricerca"
                  />
                </validation-provider>
              </validation-observer>
            </div>
            <b-input-group-append>
              <b-button
                variant="outline-primary" 
                style="border: none !important"
                type="submit"
                :disabled="action_press"
                @click.prevent="validationFormRicerca()"
              >
                <span v-if="action_press"><b-spinner small class="ml-1" /></span>
                <span v-else>Cerca</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      
      <vue-perfect-scrollbar
          class="email-user-list scroll-area"
        >

        <div class="text-center my-3" v-if="Caricato == false">
          <b-spinner 
            variant="primary"
            style="width: 3rem; height: 3rem;"
            label="loading"
          />
          <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-else> 
          <div v-if="this.error_email === false">
            <b-alert variant="danger" show>
              <h3 class="alert-heading">
                {{ this.error_email_title }}
              </h3>
              <div class="alert-body">
                <span>{{ this.error_email_message }}</span>
              </div>
            </b-alert>
          </div>

          <div v-else>
            <ul class="email-media-list">
              <b-media
                v-for="email in emails"
                :key="email.id"
                tag="li"
                no-body
                :class="{ 'mail-read': email.isRead }"
                @click="emailDettaglio(email)"
              >

                <b-media-aside 
                class="media-left mr-50" 
                v-if="$route.params.folder == 'sent'">
                  <b-avatar
                    class="avatar"
                    size="40"
                    variant="light-primary"
                    :text="email.to.destinatario_iniziale"
                  />
                  <div class="user-action">
                    <b-form-checkbox
                      style="margin-left: 12px !important"
                      
                    />
                  </div>
                </b-media-aside>
                <b-media-aside 
                class="media-left mr-50" 
                v-else>
                  <b-avatar
                    class="avatar"
                    size="40"
                    variant="light-primary"
                    :text="email.from.mittente_iniziale"
                  />
                  <div class="user-action">
                    <b-form-checkbox
                      style="margin-left: 12px !important"
                      
                    />
                  </div>
                </b-media-aside>

                <b-media-body>
                  <div class="mail-details">
                    <div class="mail-items">
                      <h5 class="mb-25"
                        v-if="$route.params.folder == 'sent'"
                        v-b-tooltip.hover.v-primary
                        :title="email.to.email"
                      >
                        {{ email.to.name }}
                      </h5>
                      <h5 class="mb-25"
                        v-else
                        v-b-tooltip.hover.v-primary
                        :title="email.from.email"
                      >
                        {{ email.from.name }}
                      </h5>
                      <span class="text-truncate">{{ email.subject }}</span>
                    </div>
                    <div class="mail-meta-item">
                      <feather-icon
                        v-if="email.attachments"
                        icon="PaperclipIcon"
                      />
                      <span class="mail-date pl-1">{{ formatDateToMonthShort(email.time, { hour: 'numeric', minute: 'numeric', }) }}</span>
                    </div>
                  </div>

                  <div class="mail-message">
                    <p class="text-truncate mb-0">
                      {{ filterTagsRubisco(email.message) }}
                    </p>
                  </div>
                </b-media-body>
              </b-media>
            </ul>
            <div
              class="no-results"
              :class="{'hidden': emails.length}"
            >
              <h5>Nessuna email da visualizzare</h5>
            </div>

          </div>

        </div>
      </vue-perfect-scrollbar>
      

    </div>

    <!-- Email View/Detail -->
    <email-view
      :class="{'show': showEmailDetails}"
      :email-view-data="emailViewData"
      :opended-email-meta="opendedEmailMeta"
      @close-email-view="showEmailDetails = false"
    />


    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :emails-meta="emailsMeta"
        messaggio=""
        :class="{'show': mqShallShowLeftSidebar}"
      />
    </portal>

    <!-- Compose Email Modal -->
    <email-compose v-model="shallShowEmailComposeModal" />
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import {
  required, email, digits, alphaDash, length, password,
} from '@validations'
import { BRow, BCol, BCard, BCardText, BImg, BAvatar, BAlert, BSpinner, 
  BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormSelect, BDropdown, BDropdownItem,
  BFormCheckbox, BMedia, BMediaAside, BMediaBody, BButton, VBTooltip } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterTagsRubisco, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import {
  ref, watch, computed,
  // onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import EmailLeftSidebar from './EmailLeftSidebar.vue'
import EmailView from './EmailView.vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import useEmail from './useEmail'
import EmailCompose from './EmailCompose.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BRow,
    BCol,
    BCard, 
    BCardText,
    BAvatar,
    BImg,
    BAlert, 
    BSpinner, 
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend, 
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton, 
    VBTooltip, 

    VuePerfectScrollbar,

    // componenti specifici per gmail
    EmailLeftSidebar,
    EmailView,
    EmailCompose,
  },
  directives: {
    'b-tooltip': VBTooltip, 
  },
  data() {
    return {
      Caricato: false,
      userData: {},

      parametro_cartella: '',

      routeParams: '',

      id_utente_loggato: '',

      campiricerca: {
        type: 'mittente_email',
        value: '',
      },
      options: [
        { value: 'mittente_email', text: 'Cerca nel mittente' },
        { value: 'destinatario_email', text: 'Cerca nel destinatario' },
        { value: 'oggetto', text: 'Cerca nell\'oggetto' },
      ],

      emails: {},
      emailsMeta: {},
      error_email: false,
      error_email_title: '',
      error_email_message: '',

      required,
      email,
      password, 
      alphaDash, 
      digits,
      length,

      action_press: false,

      showEmailDetails: false,
      emailViewData: {},
      opendedEmailMeta: {},

      perfectScrollbarSettings: '',
    }
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const { resolveLabelColor } = useEmail()

    return {    
      // UI
      perfectScrollbarSettings,

      // UI Filters
      filterTagsRubisco,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    console.log(this.userData)

    this.id_utente_loggato = this.userData.id
    
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })
  },
  mounted() {
    /*
    console.log("mounted ...")

    console.log('route.params: ');
    console.log(this.$route.params);
    console.log('valore -> '+this.$route.params.folder);
    */

    //caricamento lista email in base all'url di accesso
    // di default è INBOX => posta in arrivo
    this.fetchEmails(this.$route.params.folder);

    this.routeParams = computed(() => this.$route.params.folder)
    watch(this.routeParams, () => {
      /*
      console.log("route.value.params dopo ........");
      console.log(this.$route.params.folder)
      */

      //richiamo funzione lista email al cambio della cartella
      this.fetchEmails(this.$route.params.folder);
    })
    
  },
  methods: {
    fetchEmails(tipo_cartella){
      //aggiungi il loader iniziale
      this.Caricato = false;

      let cartella_richiesta = 'inbox'; //default => Posta in arrivo

      if(tipo_cartella !== undefined){
        cartella_richiesta = tipo_cartella //richiesta cartella specifica (es. SENT, DRAFT, ecc)
      }
      
      //console.log('vai di chiamata api -> api/crm/google/gmail/list/'+this.id_utente_loggato+'/'+cartella_richiesta)

      this.$http.get('api/crm/google/gmail/list/'+this.id_utente_loggato+'/'+cartella_richiesta).then(response => {
        //fine caricamento a prescindere dalla risposta dell'api
        this.Caricato = true;

        if(response.data.statusCode === 200){
          //ok dati ricevuti - Speriamo bene ...
          this.emails = response.data.reply.data;
          this.emailsMeta = { INBOX: 1, DRAFT: 2, SPAM: 3 };

          this.error_email = true;

          /*
          console.log(this.emails)
          console.log("qui ...")
          console.log(this.emailsMeta)
          */
        } else {
          //errore da api
          this.emails = {};
          this.emailsMeta = {}; //array vuoto

          this.error_email = false;
          this.error_email_title = 'Errore API - C0-A0';
          this.error_email_message = response.data.reply.message;
        }
      
      }).catch(e => {
        this.error_email = false;
        this.error_email_title = 'Errore API - C1-A1';
        this.error_email_message = e.reply.message;
      });
    },

    validationFormRicerca() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log(this.campiricerca)
          this.action_press = true
          
          //aggiungi il loader iniziale
          this.Caricato = false;

          let cartella_richiesta = 'inbox'; //default => Posta in arrivo

          if(this.routeParams.value !== undefined){
            cartella_richiesta = this.routeParams.value //richiesta cartella specifica (es. SENT, DRAFT, ecc)
          }

          //console.log('vai di chiamata api -> api/crm/google/gmail/search/'+this.id_utente_loggato+'/'+cartella_richiesta)

          this.$http.post('api/crm/google/gmail/search/'+this.id_utente_loggato+'/'+cartella_richiesta, 
            this.campiricerca).then(response => {
            //fine caricamento a prescindere dalla risposta dell'api
            this.Caricato = true;
            this.action_press = false;

            if(response.data.statusCode === 200){
              //ok dati ricevuti - Speriamo bene ...
              this.emails = response.data.reply.data;
              this.emailsMeta = { INBOX: 1, DRAFT: 2, SPAM: 3 };

              this.error_email = true;
            } else {
              //errore da api
              this.emails = {};
              this.emailsMeta = {}; //array vuoto

              this.error_email = false;
              this.error_email_title = 'Errore API - S0-A0';
              this.error_email_message = response.data.reply.message;
            }

          }).catch(e => {
            this.error_email = false;
            this.action_press = false;
            this.error_email_title = 'Errore API - S1-A1';
            this.error_email_message = e.reply.message;
          });

        }
      })
    },

    emailDettaglio(datiEmail){
      console.log("apertura dettaglio email .......")
      console.log(datiEmail)

      this.showEmailDetails = true;
      this.emailViewData = datiEmail;


    },

    toggleStarred(){
      console.log("cliccato su toggleStarred .......")

    }

  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

/*
bugfix per altezza del box generale delle email
per evitare scroll della finestra del browser
(anche in casp di di ridimensionamento della finestra)
se sopra c'è il box delle "bricole di pane"
*/
html .navbar-floating.footer-hidden .app-content .content-area-wrapper, html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 7.45rem + 0rem + 1.3rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 7.45rem + 0rem + 1.3rem + 0rem ) );
}
</style>
